import React, { useState } from 'react';
import './header.css'
import logo from '../../assets/Group39285.png'
import { Link } from 'react-router-dom';
import "bootstrap/js/src/collapse.js";





function Header() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);



    return (
        <nav className="navbar navbar-expand-lg bg-black px-1">
            <div className="container">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="Fire Post" />
                </a>
                <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarsExample09" aria-controls="navbarsExample09"
                    aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                    className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
                    <ul className="options navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                        <li className="nav-item">
                            <Link to={"/"} style={{ color: "#0AC8AF" }} className="nav-link">
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="#">
                                Products
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="#">
                                Latest News
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="#">
                                About Us
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="#">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>


    );
}

export default Header;
