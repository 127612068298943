import React from 'react';
import './last.css'
import mobile from '../../assets/Grouplast.png'
import google from '../../assets/Google.png'
import apple from '../../assets/Apple.png'



function Last() {
    return (
        <>
            <div className='last'>
            </div>

            <div className='lastframe '>

                <div className='row container mx-auto'>
                    <div className="pic col col-md-8 col-lg-4 col-xl-4 col-sm-12 mx-auto">
                        <img className='image' src={mobile} />
                    </div>
                    <div className="pic2 col col-md-4 col-lg-8 col-sm-12 col-xl-8 py-4 mx-auto">
                        <h2 style={{ color: 'white' }}>Download the App Today and Get Started!</h2>
                        <h4 style={{ color: 'white', marginTop: 10 }}>Download Firepost now ! </h4>

                        <div className='download mt-3'>
                            <img className='' src={apple}/>
                            <img style={{marginLeft: 5}} className='' src={google}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Last;
