// firebase.js
import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCBqUP3iVV5KZ3Vx3K3YU_Ww3TYh5-Mosc",
    authDomain: "firepostweb-75a5f.firebaseapp.com",
    projectId: "firepostweb-75a5f",
    storageBucket: "firepostweb-75a5f.appspot.com",
    messagingSenderId: "905812660547",
    appId: "1:905812660547:web:c55903119ee925e78e9ff6",
    measurementId: "G-XLNJEGXCDN"  
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Create a Firestore instance
const firestore = firebase.firestore();

export default firestore;
