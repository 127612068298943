import React from 'react';
import './main.css'
import mainVideo from '../../assets/Adventure.mp4'


function Main() {
    return (
        <div className='videoFrame'>
            <div className='container-fluid text-center px-4'>
                <video className="object-fit-contain" width="100%" controls>
                    <source src={mainVideo} type="video/mp4" />
                </video>

            </div>
        </div>
    );
}

export default Main;
