import React from 'react';
import Header from '../header/index'
import Main from '../main';
import Slider from '../slider';
import Carousal from '../carousal';
import Mobile from '../mobile';
import './home.css'
import Last from '../last';
import Footer from '../footer';


function Home() {
  return (
    <div className='background'> 
      <Header/>
      <Main/>
      <Slider/>
      <Carousal />
      <Mobile />
      <Last />
      <Footer />
    </div>
  );
}

export default Home;
