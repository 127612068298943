import React from 'react';
import './slider.css'
import { ComparisonSlider } from 'react-comparison-slider';
import image1 from '../../assets/Connectbymap.png'
import image2 from '../../assets/2.png'
import bulbscroll from '../../assets/blubscroll.png'


function Slider() {
    return (
        <div className='frame my-4'>
            <div className='container-fluid text-center'>
                <ComparisonSlider
                    defaultValue={50}
                    itemOne={<div className="bg-red-200"><img src={image1} height="90%" width="100%" /></div>}
                    itemTwo={<div className="bg-blue-200"><img src={image2} height="90%" width="100%" /></div>}
                    aspectRatio={16 / 9}
                    orientation="horizontal"
                    handleBefore={
                        <div className="bg-gradient-to-t from-white to-transparent w-2 h-full"></div>
                      }
                      handleAfter={
                        <div className="bg-gradient-to-b from-white to-transparent w-2 h-full"></div>
                      }
                    handle={({ isFocused }) => {
                        return (
                          <div
                            className={([
                              'flex items-center justify-center',
                              { ring: isFocused },
                            ])}
                          >
                            <img className='bulbscroll' src={bulbscroll} height={70} width={70}/>
                          </div>
                        );
                      }}
                />
            </div>
        </div>
    );
}

export default Slider;
