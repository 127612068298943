import React from 'react';
import './mobile.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import mobile1 from '../../assets/White bezzel.png'
import mobile2 from '../../assets/White bezzel1.png'
import mobile3 from '../../assets/White bezzel2.png'
import mobile4 from '../../assets/White bezzel3.png'
import 'swiper/css';
import { EffectCoverflow, Pagination } from 'swiper/modules';


function Mobile() {
    return (
        <div className='framemobile text-center'>
            <h3 className='my-5' style={{ color: "white" }}>Single-Tap Straight Forward Interface</h3>
            <div className='container-fluid my-4'>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    modules={[EffectCoverflow, Pagination]}
                    spaceBetween={-190}
                    slidesPerView={3}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    scrollbar={{ draggable: true }}
                >
                    <SwiperSlide><img src={mobile1} height="50%" width="50%" /></SwiperSlide>
                    <SwiperSlide><img src={mobile2} height="50%" width="50%" /></SwiperSlide>
                    <SwiperSlide><img src={mobile3} height="50%" width="50%" /></SwiperSlide>
                    <SwiperSlide><img src={mobile4} height="50%" width="50%" /></SwiperSlide>
                    ...
                </Swiper>
            </div>
        </div>
    );
}

export default Mobile;
