import React, { useRef, useState } from 'react';

import './carousal.css'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import frame1 from '../../assets/Frame1.png'
import frame2 from '../../assets/Frame2.png'
import frame3 from '../../assets/Frame3.png'
import frame4 from '../../assets/Frame4.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';

function Carousal() {
    return (
        <div className='framer my-4'>
            <>
                <Swiper
                    // autoHeight={true}
                    spaceBetween={100}
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    style={{
                        "--swiper-pagination-color": "#0AC8AF",
                        "--swiper-pagination-bullet-size": "12px",
                        "--swiper-pagination-bullet-inactive-color": "#FFF"

                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                >
                    {/* <div className='' style={{ backgroundColor: 'black', height: 590 }}> */}

                        <SwiperSlide className='Swiping'>
                            <img src={frame1} className="img-fluid" width="100%" alt="..." />
                        </SwiperSlide>
                        <SwiperSlide className='Swiping'>

                            <img src={frame2} className="img-fluid" width="100%" alt="..." />


                        </SwiperSlide>
                        <SwiperSlide className='Swiping'>

                            <img src={frame3} className="img-fluid" width="100%" alt="..." />

                        </SwiperSlide>

                        <SwiperSlide className='Swiping'>
                            <img src={frame4} className="img-fluid" width="100%" alt="..." />


                        </SwiperSlide>
                    {/* </div> */}
                </Swiper>
            </>
        </div >
    );
}

export default Carousal;
