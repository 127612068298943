import React, { useEffect, useState } from 'react';
import './privacy.css'
import Header from '../header';
import Footer from '../footer';
import firestore from '../../data/firebase';


function Privacy() {

    const [data, setData] = useState([]);
    let [bool, setbool] = useState(true);

    useEffect(() => {

        const collectionRef = firestore.collection('privacy1');

        // Fetch data from the Firestore collection
        collectionRef.get()
            .then((querySnapshot) => {
                const fetchedData = querySnapshot.docs.map((doc) => doc.data());
                setData(fetchedData[0]);
                console.log(fetchedData[0]);
                setbool(false)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);



    return (
        <>
            <Header />
            <div className='privacy'>
                <div className='card container p-4'>
                    <h1 className='py-4'>Privacy Policy</h1>
                    {bool ? <div className="spinner-border text-light mx-auto" style={{ marginBottom: 200, marginTop: 200 }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> :
                        <>
                            <ul>
                                {Object.keys(data).map((item, key) => (
                                    <li key={key}><p>{data[item]}</p></li>
                                ))}
                                <li><p>The Firepost App complies with the Google API Services User Data Policy(
                                    <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank'>
                                         Google API Services User Data Policy
                                    </a>), including the Limited Use requirements
                                </p></li>
                            </ul>

                            {/* <ul>

                            </ul> */}
                        </>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;
