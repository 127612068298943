import React from 'react';
import './footer.css'
import icon1 from '../../assets/Group 12.png'
import icon2 from '../../assets/Group 13.png'
import icon3 from '../../assets/Group 14.png'
import icon4 from '../../assets/Group 15.png'
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div className='framefooter'>
            <>
                {/* Footer */}
                <footer className="text-center text-lg-start bg-black text-muted">
                    {/* Section: Social media */}
                    <section className="d-flex justify-content-center justify-content-lg-between p-4">
                        {/* Left */}
                        {/* Right */}
                    </section>
                    {/* Section: Social media */}
                    {/* Section: Links  */}
                    <section className="">
                        <div className="container text-center text-md-start mt-4">
                            {/* Grid row */}
                            <div className="row mt-2">
                                {/* Grid column */}
                                <div className="col-md-3 col-lg-4 col-xl-4 mx-auto mb-4">
                                    {/* Content */}
                                    <h4 className="text-uppercase fw-bold mb-4 text-white">
                                        Fire Post
                                    </h4>
                                    <p className='text-white'>
                                        Download Firepost free of cost and connect with world of Map.<br />
                                        Available on App store and Play store.
                                    </p>
                                </div>
                                {/* Grid column */}
                                {/* Grid column */}
                                <div className="col-md-2 col-lg-2 col-xl-3 mb-4 mx-auto">
                                    {/* Links */}
                                    <h6 className="text-uppercase fw-bold mb-4 text-white">Quick Links</h6>
                                    <p>
                                        <a href="#!" className="text-white">
                                            About
                                        </a>
                                    </p>
                                    <p>
                                        <a href="#!" className="text-white">
                                            Blogs
                                        </a>
                                    </p>
                                    <p>
                                        <a href="#!" className="text-white">
                                            Contact
                                        </a>
                                    </p>

                                </div>
                                {/* Grid column */}
                                {/* Grid column */}
                                {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4 text-white">Products</h6>
                                    <p>
                                        <a href="#!" className="text-white">
                                            Mutual Funds
                                        </a>
                                    </p>
                                    <p>
                                        <a href="#!" className="text-white">
                                            Stocks
                                        </a>
                                    </p>
                                    <p>
                                        <a href="#!" className="text-white">
                                            Robo Advisor
                                        </a>
                                    </p>
                                </div> */}
                                {/* Grid column */}
                                {/* Grid column */}
                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                    {/* Links */}
                                    <h6 className="text-uppercase fw-bold mb-4 text-white">Contact</h6>
                                    <p className='text-white'>
                                        contact.firepost@gmail.com
                                    </p>
                                    <p className='text-white'>
                                        contact@firepost.info
                                    </p>

                                </div>
                                {/* Grid column */}
                            </div>
                            {/* Grid row */}
                        </div>
                    </section>
                    {/* Section: Links  */}
                    {/* Copyright */}
                    <div className='row container mx-auto'>
                        <div
                            className="col-md-3 col-lg-4 col-xl-4 mx-auto mb-4"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                        >
                            <div className='row'>
                                <div className='col-md-2 col-lg-2 col-xl-2'>
                                    <p>
                                        <a href="#!" style={{marginLeft:-10}} className="text-white">
                                            Terms
                                        </a>
                                    </p>
                                </div>
                                <div className='col-md-4 col-lg-4 col-xl-4'>

                                    <Link className='text-white' to={'/privacy_policy'}>
                                            Privacy Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-2 col-lg-2 col-xl-3 mx-auto mb-4 text-white"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                        >
                            © 2023
                            Firepost. All rights reserved.               </div>
                        <div
                            className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                        >
                            <a href="https://www.instagram.com/_u/firepost_explorer/" className="text-white" target="_blank">

                                <img style={{ marginLeft: 0 }} src={icon2} height="30px" width="30px" />
                            </a>
                            <a href="https://www.linkedin.com/in/firepost-explorer-b39596286/" className="text-white" target="_blank">

                                <img style={{ marginLeft: 10 }} src={icon3} height="30px" width="30px" />
                            </a>

                            <a href="https://www.facebook.com/profile.php?id=100095400790653" className="text-white" target="_blank">

                                <img style={{ marginLeft: 10 }} src={icon4} height="30px" width="30px" />
                            </a>

                        </div>
                    </div>
                    {/* Copyright */}
                </footer>
                {/* Footer */}
            </>

        </div >
    );
}

export default Footer;
