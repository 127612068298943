import Home from './component/home'
import AppRouter from './router/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';


function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
