import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../component/home'
import Privacy from '../component/privacy';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/privacy_policy" element={<Privacy />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
